export const spellingGrammarCongratsMessages = [
    {
        title: 'Excellent job!',
        message:
            'All of the elements appear to be correctly spelled and grammatically accurate. Congratulations on the well-formulated text!',
    },
    {
        title: 'Awesome work!',
        message:
            'All aspects appear to be grammatically accurate and spelled correctly. Congratulations on the well-written content!',
    },
    {
        title: 'Nicely done!',
        message:
            'Everything seems perfectly spelled and grammatically accurate. Congratulations on producing such a cohesive text!',
    },
    {
        title: 'Superb job!',
        message:
            'All of the elements appear to be grammatically correct and properly spelled. Congrats on the well-structured content!',
    },
    {
        title: 'Great work!',
        message:
            'Everything looks properly spelled and grammatically correct. Congratulations on crafting such a well-written piece!',
    },
    {
        title: 'Well done!',
        message:
            'Everything is grammatically accurate and correctly spelled. Congratulations on crafting such a clean, well-formulated text!',
    },
    {
        title: 'Wonderful work!',
        message:
            'Everything appears to be spelled correctly and grammatically accurate. Congrats on the well-structured text!',
    },
    {
        title: 'Impressive effort!',
        message: 'Everything is spelled accurately and grammatically sound. Well done!',
    },
    {
        title: 'Fantastic job!',
        message:
            'Your spelling and grammar are on point. Congratulations on such a well-structured piece!',
    },
    {
        title: 'Outstanding work!',
        message:
            'Every element is correctly spelled and grammatically accurate. Congratulations on creating such a well-written text!',
    },
    {
        title: 'Excellent effort!',
        message:
            'Everything is spelled accurately and grammatically correct. Well done on writing such a smooth, cohesive text!',
    },
    {
        title: 'Incredible job!',
        message:
            'All components are correctly spelled and grammatically flawless. Congrats on creating such a polished text!',
    },
    {
        title: 'Terrific work!',
        message:
            'Your spelling and grammar are perfect throughout. Congrats on delivering such a well-crafted piece!',
    },
    {
        title: 'Great effort!',
        message:
            'The spelling and grammar are flawless throughout. Congratulations on the well-executed text!',
    },
    {
        title: 'Boom!',
        message:
            'Your spelling and grammar are spot on. Congratulations on producing such a well-composed text!',
    },
    {
        title: 'Impressive job!',
        message:
            'All the elements are grammatically sound and spelled correctly. Well done on creating such a clear and concise piece!',
    },
    {
        title: 'Stellar job!',
        message:
            'Everything is spelled and punctuated correctly, with perfect grammar. Congrats on the beautifully structured content!',
    },
    {
        title: 'Amazing work!',
        message:
            'The spelling and grammar are precise and accurate. Well done on crafting such a polished and cohesive piece!',
    },
    {
        title: 'Exceptional effort!',
        message:
            'Your grammar and spelling are impeccable. Congrats on such a well-formulated and structured text!',
    },
    {
        title: 'Flawless work!',
        message:
            'Every detail is grammatically accurate and spelled correctly. Congratulations on producing a well-written and organized text!',
    },
    {
        title: 'Fantastic effort!',
        message:
            'The spelling and grammar are flawless. Congrats on crafting such a well-organized and articulate piece!',
    },
    {
        title: 'Superb work!',
        message:
            'All aspects are grammatically accurate and perfectly spelled. Well done on such a well-structured text!',
    },
    {
        title: 'Excellent writing!',
        message:
            'Your spelling and grammar are impeccable. Congratulations on creating a coherent and polished piece!',
    },
    {
        title: 'Magnificent job!',
        message:
            'Everything is grammatically sound and correctly spelled. Well done on producing such a clear and well-written text!',
    },
    {
        title: 'Nailed it!',
        message:
            'Your spelling and grammar are perfect, and the text flows beautifully. Congrats on crafting such a polished piece!',
    },
];

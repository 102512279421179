import { defaultUsageDateSortItem } from '../content/usagePageSortOptions';
import { usageSettingsActions } from './usageSettings.actions';

const usageSettingsInitialState = {
    usageData: [],
    isDataLoading: false,
    queryParams: {
        search: null,
        dateRange: defaultUsageDateSortItem,
        userSort: null,
    },
};

export const usageSettingsReducer = (state = usageSettingsInitialState, { type, payload }) => {
    switch (type) {
        case usageSettingsActions.UPDATE_USAGE_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        default:
            return state;
    }
};

import { DOMParser } from '@tiptap/pm/model';
import { isFunction, isNumber } from 'lodash';
import { TextSelection } from 'prosemirror-state';

import { colorPalette } from '../../../../resources/styles/colorPalette';

/**
 * Editor Transaction Helpers
 *
 * This module provides helper functions for managing editor transactions,
 * allowing various operations on the document such as updating node attributes,
 * inserting new nodes, creating and removing highlights, and deleting nodes.
 * The main goal is to provide flexibility in handling undo history and other
 * editing functions.
 */

/**
 * Creates a transaction and optionally prevents adding it to the undo history.
 *
 * @param {*} editor - The editor instance.
 * @param {*} operations - Function that performs operations on the transaction (`tr`).
 * @param {boolean} [addToHistory=false] - Whether to add the transaction to the undo history.
 */
export const createTransaction = (editor, operations, addToHistory = false) => {
    const { tr } = editor.state;
    if (!addToHistory) {
        tr.setMeta('addToHistory', false); // Prevent adding this to the undo history
    }

    operations(tr);
    editor.view.dispatch(tr);
};

/**
 * Updates attributes of a node of a given type in the document.
 *
 * @param {*} editor - The editor instance.
 * @param {string} nodeType - The type of the node to update.
 * @param {*} attributes - The new attributes for the node.
 */
export const updateNodeAttributes = (editor, nodeType, attributes) => {
    createTransaction(editor, (tr) => {
        editor.state.doc.descendants((node, pos) => {
            if (node.type.name === nodeType) {
                // Update the attributes of the node
                tr.setNodeMarkup(pos, undefined, {
                    ...node.attrs,
                    ...attributes,
                });

                return false; // Stop iteration once the node is found
            }
        });
    });
};

/**
 * Inserts a new node into the document.
 *
 * @param {*} editor - The editor instance.
 * @param {number} to - The position to insert the node at.
 * @param {string} nodeType - The type of the node to insert
 * @param {*} attrs - Attributes for the node.
 * @param {*} content - Content for the node.
 * @param {boolean} unique - Whether this node should be unique in the document.
 */
export const insertNode = (editor, to, nodeType, attrs, unique = false) => {
    createTransaction(editor, (tr) => {
        const { doc } = editor.state;

        if (unique) {
            doc.descendants((node, pos) => {
                if (node && node.type.name === nodeType && isNumber(node.nodeSize)) {
                    tr.delete(pos, pos + node.nodeSize);
                }

                return true;
            });
        }

        tr.insert(
            to,
            editor.schema.nodeFromJSON({
                type: nodeType,
                attrs: attrs,
            })
        );
    });
};

/**
 * Creates a transaction to add a highlight mark to a range of text.
 *
 * @param {*} editor - The editor instance.
 * @param {number} from - The start position of the highlight.
 * @param {number} to - The end position of the highlight.
 * @param {string} color - The color of the highlight.
 */
export const createHighlightTransaction = (editor, from, to, color) => {
    createTransaction(editor, (tr) => {
        tr.addMark(
            from,
            to,
            editor.schema.marks.highlight.create({
                color,
            })
        );
    });
};

/**
 * Creates a transaction to remove a highlight mark from a range of text.
 *
 * @param {*} editor - The editor instance.
 * @param {number} from - The start position of the highlight.
 * @param {number} to - The end position of the highlight.
 */
export const createRemoveHighlightTransaction = (editor, from, to) => {
    createTransaction(editor, (tr) => {
        tr.removeMark(from, to, editor.schema.marks.highlight);
    });
};

/**
 * Deletes a node from the document.
 *
 * @param {*} editor - The editor instance.
 * @param {string} nodeType - The type of the node to delete.
 */
export const deleteNode = (editor, nodeType) => {
    const { tr, doc } = editor.state;
    let foundNode = null;

    doc.descendants((node, pos) => {
        if (node.type.name === nodeType) {
            foundNode = { node, pos };
            return false; // Stop iteration once the node is found
        }
    });

    if (
        foundNode &&
        isNumber(foundNode.pos) &&
        foundNode.node &&
        isNumber(foundNode.node.nodeSize)
    ) {
        // Create a transaction to delete the node
        const deleteTr = tr.delete(foundNode.pos, foundNode.pos + foundNode.node.nodeSize);
        deleteTr.setMeta('addToHistory', false); // Prevent adding this to the undo history
        editor.view.dispatch(deleteTr);
    }
};

/**
 * Creates a transaction to remove the highlight mark from the editor and set the selection and than restore to the original view.
 * @param {Editor} editor - The editor instance.
 * @param {number} from - The start position.
 * @param {number} to - The end position.
 */
export const executeAiCommandWithHighlight = (editor, from, to, callback) => {
    createRemoveHighlightTransaction(editor, from, to);

    const view = editor.view;
    const { state } = view;
    const tr = state.tr.setSelection(TextSelection.create(state.doc, from, to));

    view.dispatch(tr.setMeta('addToHistory', false));

    editor.commands.focus();

    if (isFunction(callback)) callback();

    createHighlightTransaction(editor, from, to, colorPalette.colorSelectedHighlight);
    editor.commands.setTextSelection({ from: to, to: to });
};

/**
 * Clears the first node if it is empty without storing it in history.
 * @param {Transaction} transaction - The transaction instance.
 * @param {number} from - The start position.
 * @param {number} to - The end position.
 */
export const clearFirstNodeIfEmptyTransaction = (transaction, from, to) => {
    const slice = transaction.doc.slice(from, to);
    const firstNode = slice.content.firstChild;

    if (firstNode && firstNode.textContent === '') {
        transaction.deleteRange(from, from + firstNode.nodeSize);

        const newDoc = transaction.doc;
        const newTo = Math.max(from, to - firstNode.nodeSize);

        transaction.setSelection(TextSelection.create(newDoc, from, newTo));
    }
};

/**
 * Inserts HTML content at a given position in the editor.
 * @param {Transaction} transaction - The transaction instance.
 * @param {number} pos - The position to insert the content at.
 * @param {string} htmlContent - The HTML content to insert.
 */
export const insertHtmlContentTransaction = (transaction, pos, htmlContent) => {
    const { schema } = transaction.doc.type;

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    const fragment = DOMParser.fromSchema(schema).parse(tempDiv);

    if (fragment) {
        transaction.replaceWith(pos, pos, fragment);

        const newDoc = transaction.doc;
        const newTo = pos + fragment.content.size;

        transaction.setSelection(TextSelection.create(newDoc, pos, newTo));
    }
};

import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Form } from 'antd';

import { KEY_CODES } from '../../../../constants/constants';
import { countries, defaultCountry, featuredCountries } from '../../../../constants/countries';
import { keywordResearchTabs } from '../../../../constants/keywordResearchTabs';
import { defaultLanguage, featuredLanguages, languages } from '../../../../constants/languages';
import { errorNotificationMessage, notificationType } from '../../../../constants/notificationType';
import { keywordPathes } from '../../../../constants/queryPathes';
import { urlPattern } from '../../../../constants/regularExpression';
import { statusCodes } from '../../../../constants/statusCodes';
import { subscriptionLimits } from '../../../../constants/subscriptionLimits';

import NoCreditsModal from '../../../common/createNew/NoCreditsModal';
import KeywordSearchInput from '../../../common/inputs/KeywordSearchInput';
import KeywordSelect from '../../../common/selects/KeywordSelect';
import KeywordResearchSearchButton from './KeywordResearchSearchButton';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getArrayOptionsFromObject } from '../../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import {
    getKeywordLengthErrorMessage,
    getKeywordUrlErrorMessage,
    isKeywordLengthValid,
} from '../../../../utils/helpers/keywordHelpers';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../utils/hooks/useOpen';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { increaseSubscriptionLimits } from '../../account/billing/store/billingSettings.actions';
import {
    selectKeywordResearchesLimit,
    selectKeywordResearchesUsed,
} from '../../account/billing/store/billingSettings.selectors';
import {
    setCurrentKeyword,
    setInitialKeywordListSort,
    setKeywordListFilters,
    updateKeywordQueryParam,
} from '../store/keywordResearchSettings.actions';
import {
    selectCurrentKeyword,
    selectKeywordForUpdate,
    selectKeywordResearchTab,
} from '../store/keywordResearchSettings.selectors';

const classes = {
    keywordResearchHeaderWrapper: 'keyword-header-wrapper d-flex w-100',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
};

const KeywordResearchHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedTab = useSelector(selectKeywordResearchTab);
    const keywordForUpdate = useSelector(selectKeywordForUpdate);
    const currentKeyword = useSelector(selectCurrentKeyword);
    const keywordsLimit = useSelector(selectKeywordResearchesLimit);
    const keywordsUsed = useSelector(selectKeywordResearchesUsed);
    const userLanguagePreference = useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;
    const { isOpen, handleOpen } = useOpen();

    const organizationSlug = useOrganizationSlug();

    const [isInputError, setIsInputError] = useState(false);

    const [keywordSearchData, setKeywordSearchData] = useState({
        keyword: keywordForUpdate || '',
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [hideError, setHideError] = useState(true);

    useEffect(() => {
        setKeywordSearchData((prev) => ({
            ...prev,
            keyword: '',
        }));

        // eslint-disable-next-line
    }, [selectedTab]);

    useEffect(() => {
        if (keywordForUpdate) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keyword: keywordForUpdate,
            }));
        } else if (currentKeyword && selectedTab === currentKeyword?.type) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keyword: currentKeyword?.keyword,
                country_code: currentKeyword?.country_code,
                language_code: currentKeyword?.language_code,
            }));
        } else {
            setKeywordSearchData((prev) => ({
                ...prev,
                keyword: '',
                country_code: userSearchEnginePreference,
                language_code: userLanguagePreference,
            }));
        }

        // eslint-disable-next-line
    }, [keywordForUpdate, currentKeyword, selectedTab]);

    const handleChangeKeyword = (event) => {
        setHideError(true);
        const eventValue = event?.target?.value;

        setKeywordSearchData((prev) => ({
            ...prev,
            keyword: eventValue,
        }));
        setIsInputError(false);
    };

    const handleChangeLocation = (value) => {
        setKeywordSearchData((prev) => ({
            ...prev,
            country_code: value,
        }));
    };

    const handleChangeLanguage = (value) => {
        setKeywordSearchData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const createKeyword = () => {
        setIsLoading(true);

        axiosAPI
            .post(
                keywordPathes.createKeywordResearch,
                {
                    ...keywordSearchData,
                    keyword: keywordSearchData?.keyword?.trim(),
                    type: selectedTab,
                },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.create) {
                    navigate(`/${organizationSlug}/research/${result.data?.id}`);
                    batch(() => {
                        dispatch(setCurrentKeyword(result.data));
                        dispatch(setKeywordListFilters(null));
                        dispatch(setInitialKeywordListSort());
                    });
                    dispatch(
                        increaseSubscriptionLimits({
                            limit: subscriptionLimits.keywordResearchUsed,
                            value: 1,
                        })
                    );
                }
            })
            .catch(({ response }) => {
                openNotification(
                    notificationType.error,
                    'Error',
                    response?.data?.message || errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
                dispatch(updateKeywordQueryParam({ search: '' }));
            });
    };

    const getHelpMessageAndStatus = (activeTab, keyword = '', showMessage = false) => {
        let message = '';
        let status = 'success';

        const trimmedKeyword = keyword.trim();

        if (showMessage && trimmedKeyword) {
            switch (activeTab) {
                case keywordResearchTabs.url.key:
                    if (!urlPattern.test(trimmedKeyword)) {
                        message = getKeywordUrlErrorMessage();
                        status = 'error';
                    }
                    break;
                case keywordResearchTabs.keyword.key:
                    if (!isKeywordLengthValid(trimmedKeyword)) {
                        message = getKeywordLengthErrorMessage();
                        status = 'error';
                    }
                    break;
                default:
                    break;
            }
        }

        return { helpMessage: message, validateStatus: status };
    };

    const { helpMessage, validateStatus } = getHelpMessageAndStatus(
        selectedTab,
        keywordSearchData.keyword,
        !hideError
    );

    const checkKeywordHasText = (keyword) => {
        const keywordArray = keyword.split(' ');

        return keywordArray.some((item) => item.length > 0);
    };

    const handleSearch = () => {
        if (keywordsUsed >= keywordsLimit || !keywordsLimit) {
            handleOpen();
            return;
        }

        if (checkKeywordHasText(keywordSearchData.keyword)) {
            switch (selectedTab) {
                case keywordResearchTabs.url.key:
                    if (urlPattern.test(keywordSearchData.keyword)) {
                        createKeyword();
                        return;
                    }
                    break;

                case keywordResearchTabs.keyword.key:
                default:
                    if (isKeywordLengthValid(keywordSearchData.keyword)) {
                        createKeyword();
                        return;
                    }
                    break;
            }

            setHideError(false);
        }

        setIsInputError(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === KEY_CODES.ENTER) {
            handleSearch();
        }
    };

    return (
        <>
            <Form className={classes.keywordResearchHeaderWrapper} onKeyDown={handleKeyDown}>
                <Form.Item help={helpMessage} validateStatus={validateStatus}>
                    <KeywordSearchInput
                        placeholder={
                            selectedTab === keywordResearchTabs.keyword.key
                                ? 'Enter keyword or phrase (e.g. best running shoes)…'
                                : 'Enter URL (e.g. https://www.google.com)…'
                        }
                        isInputError={isInputError}
                        value={keywordSearchData.keyword}
                        onChange={handleChangeKeyword}
                    />
                </Form.Item>
                <div className={classes.keywordLocationSelect}>
                    <KeywordSelect
                        placeholder='Select Location'
                        options={getArrayOptionsFromObject({
                            values: countries,
                            featured: featuredCountries,
                        })}
                        value={keywordSearchData.country_code}
                        onChange={handleChangeLocation}
                    />
                </div>
                <div className={classes.keywordLanguageSelect}>
                    <KeywordSelect
                        placeholder='Select Language'
                        options={getArrayOptionsFromObject({
                            values: languages,
                            featured: featuredLanguages,
                        })}
                        value={keywordSearchData.language_code}
                        onChange={handleChangeLanguage}
                    />
                </div>
                <KeywordResearchSearchButton isLoading={isLoading} handleRun={handleSearch} />
            </Form>
            <NoCreditsModal
                isOpen={isOpen}
                handleOpen={handleOpen}
                keywordsUsed={keywordsUsed}
                keywordsLimit={keywordsLimit}
            />
        </>
    );
};

export default KeywordResearchHeader;

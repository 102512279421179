import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Tooltip, Typography } from 'antd';
import { isNumber } from 'lodash';

import ChatGPTIcon from '../../../../../resources/icons/editor/ChatGPTIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import {
    getThemeColorClass,
    getTooltipOverlayClass,
} from '../../../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import useEditorAiCredits from '../../hooks/useEditorAiCredits';
import useSelectedTextWordCount from '../../hooks/useSelectedTextWordCount';
import { getCorrectEndPosition, getCorrectStartPosition } from '../../utils/aiSpellCheckerHelpers';
import { createHighlightTransaction, insertNode } from '../../utils/editorTransactionHelpers';

import { EDITOR_AI_SELECTED_WORDS_LIMIT } from '../../constants/aiEditorConstants';
import { nodeTypes } from '../../constants/nodeTypes';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setAISpellCheckerVisible } from '../../store/tiptapEditor.actions';
import { selectAIStateLoading } from '../../store/tiptapEditor.selectors';

export const WORD_COUNT_LINK_CLASS = 'word-count-link';
export const AI_BUTTON_CLASS = 'ai-button';

const classes = {
    AISection: 'ai-section',
    AIButton: `${AI_BUTTON_CLASS}`,
    AIActiveButton: 'ai-active-button',
    AILoading: 'ai-loading',
    AILanguageInfo: 'ai-language-info ai-option-item text-decoration-dotted',

    loadIconWrapper: 'load-icon-wrapper d-flex',
    wordCountLink: `${WORD_COUNT_LINK_CLASS} text-decoration-dotted`,

    tooltipOverlay: 'toolbar-tooltip-overlay',
    aiSelectTooltipOverlay: 'ai-select-tooltip-overlay',
    wordCountTooltipOverlay: 'word-count-tooltip-overlay',
};

const AISection = () => {
    const dispatch = useDispatch();

    const { editor } = useCurrentEditor();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);
    const aiLoading = useSelector(selectAIStateLoading);

    const { hasSufficientAiCredits, aiCreditsResetDate, redirectToUpgrade } = useEditorAiCredits();
    const { isAIFeatureEnabled, selectTextWithinLimit } = useSelectedTextWordCount();

    const [isAiTooltipVisible, setIsAiTooltipVisible] = useState(false);

    const handleClick = useCallback(() => {
        if (aiLoading) {
            return;
        }

        const { from, to } = editor?.state?.selection || {};

        if (isNumber(from) && isNumber(to)) {
            dispatch(setAISpellCheckerVisible(true));
            editor.commands.setSpellCheckerVisible(true);

            const correctedEndPos = getCorrectEndPosition(editor, to);
            const correctedStartPos = getCorrectStartPosition(editor, from);

            insertNode(
                editor,
                correctedEndPos,
                nodeTypes.aiSpellChecker,
                { from: correctedStartPos, to: correctedEndPos },
                true
            );
            createHighlightTransaction(
                editor,
                correctedStartPos,
                correctedEndPos,
                colorPalette.colorSelectedHighlight
            );
            editor.commands.setSelectedRange(correctedStartPos, correctedEndPos);
            editor.commands.setTextSelection({
                from: correctedEndPos,
                to: correctedEndPos,
            });
        }
    }, [aiLoading, dispatch, editor]);

    const getTooltipTitle = () => {
        if (!hasSufficientAiCredits) {
            return (
                <>
                    Not enough AI credits to complete this action. Your AI credits will reset on
                    {` ${aiCreditsResetDate}`} or{' '}
                    <Typography.Link onClick={redirectToUpgrade}>upgrade</Typography.Link> your plan
                    now for more credits.
                </>
            );
        } else if (!isAIFeatureEnabled) {
            return (
                <>
                    To enable AI: First select some text (up to{' '}
                    <Tooltip
                        overlayClassName={`${classes.tooltipOverlay} ${
                            classes.wordCountTooltipOverlay
                        } ${getTooltipOverlayClass(darkMode)}`}
                        placement='top'
                        title={`Click to select up to ${EDITOR_AI_SELECTED_WORDS_LIMIT} words.`}
                    >
                        <Typography.Link
                            className={classes.wordCountLink}
                            onClick={() => {
                                setIsAiTooltipVisible(false);
                                selectTextWithinLimit();
                            }}
                        >
                            {EDITOR_AI_SELECTED_WORDS_LIMIT} words
                        </Typography.Link>
                    </Tooltip>
                    ) from your content below, then choose a command from the menu.
                    <br />
                    One AI credit per AI command.
                </>
            );
        } else {
            return '';
        }
    };

    return (
        <div className={`${classes.AISection} ${getThemeColorClass(darkMode)}`}>
            <Tooltip
                open={isAiTooltipVisible}
                overlayClassName={`${classes.tooltipOverlay} ${
                    classes.aiSelectTooltipOverlay
                } ${getTooltipOverlayClass(darkMode)}`}
                placement='top'
                title={getTooltipTitle()}
                onOpenChange={(visible) => setIsAiTooltipVisible(visible)}
            >
                <Button
                    className={`${classes.AIButton} ${
                        aiLoading || isAIFeatureEnabled || hasSufficientAiCredits
                            ? classes.AIActiveButton
                            : ''
                    }`}
                    icon={
                        <div
                            className={`${classes.loadIconWrapper} ${
                                aiLoading ? classes.AILoading : ''
                            }`}
                        >
                            <ChatGPTIcon color={iconColor} />
                        </div>
                    }
                    disabled={!isAIFeatureEnabled || !hasSufficientAiCredits}
                    onClick={handleClick}
                >
                    <Typography className={` ${getThemeColorClass(darkMode)}`}>AI</Typography>
                </Button>
            </Tooltip>
        </div>
    );
};

export default AISection;

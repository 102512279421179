import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Tooltip } from 'antd';
import { debounce } from 'lodash';

import {
    AIExtendIcon,
    AIImproveWritingIcon,
    AILanguageIcon,
    AIRephraseIcon,
    AIShortenIcon,
    AISpellingGrammarIcon,
    AISummarizeIcon,
    AIToneOfVoiceIcon,
} from '../../../../resources/icons/ai';
import UnorderedListIcon from '../../../../resources/icons/editor/UnorderedListIcon';

import { filterOptions } from '../../../../utils/helpers/filterHelpers';
import { getTooltipOverlayClass } from '../../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { executeAiCommandWithHighlight } from '../utils/editorTransactionHelpers';
import useAiTextOptions from './useAiTextOptions';

import { aiSpellCheckerOptionKeys } from '../constants/aiEditorConstants';
import { nodeTypes } from '../constants/nodeTypes';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentOptimizerLanguageCode } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

const classes = {
    aiResponseOptionItem: 'ai-option-item d-flex align-items-center',
    aiLanguageInfo: 'ai-language-info text-decoration-dotted',

    tooltipOverlay: 'toolbar-tooltip-overlay',
    aiOptionTooltipOverlay: 'ai-option-tooltip-overlay',
};

const toneOptions = [
    { key: 'conversational', label: 'Conversational' },
    { key: 'friendly', label: 'Friendly' },
    { key: 'persuasive', label: 'Persuasive' },
    { key: 'business', label: 'Professional' },
    { key: 'objective', label: 'Straightforward' },
];

const useAiSpellCheckerItems = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);
    const languageCode = useSelector(selectCurrentOptimizerLanguageCode);

    const iconColor = useIconColor();
    const { aiTextOptions } = useAiTextOptions();

    const handleToneClick = ({ key }) => {
        const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

        editor.commands.setActiveAiCommand(aiSpellCheckerOptionKeys.toneOfVoice);
        executeAiCommandWithHighlight(editor, from, to, () => {
            editor.chain().focus().aiAdjustTone(key, aiTextOptions).run();
        });
    };

    const toneOptionsWithClick = toneOptions.map((option) => ({
        ...option,
        onClick: () => handleToneClick(option),
    }));

    const aiItems = useMemo(
        () => [
            {
                key: aiSpellCheckerOptionKeys.aiLanguage,
                icon: <AILanguageIcon color={iconColor} />,
                name: (
                    <span className={classes.aiLanguageInfo}>
                        AI Language: {languageCode.toUpperCase() || ''}
                    </span>
                ),
                tooltip:
                    'AI-generated content will be in the same language selected for the Optimizer.',
                click: () => {},
            },
            {
                key: aiSpellCheckerOptionKeys.spellingGrammar,
                icon: <AISpellingGrammarIcon color={iconColor} />,
                name: 'Spelling & Grammar',
                tooltip: 'Automatically fixes spelling & grammar mistakes.',
                click: () => editor.chain().focus().aiFixSpellingAndGrammar(aiTextOptions).run(),
            },
            {
                key: aiSpellCheckerOptionKeys.improveWriting,
                icon: <AIImproveWritingIcon color={iconColor} />,
                name: 'Improve Writing',
                tooltip: 'Enhances the overall quality of the selected text.',
                click: () => editor.commands.aiImproveWriting(aiTextOptions),
            },
            {
                key: aiSpellCheckerOptionKeys.extend,
                icon: <AIExtendIcon color={iconColor} />,
                name: 'Extend Content',
                tooltip: 'Adds relevant content to the selected text.',
                click: () =>
                    editor
                        .chain()
                        .focus()
                        .aiComplete({ ...aiTextOptions, append: true })
                        .run(),
            },
            {
                key: aiSpellCheckerOptionKeys.shorten,
                icon: <AIShortenIcon color={iconColor} />,
                name: 'Shorten Content',
                tooltip: 'Reduces the selected text.',
                click: () => editor.chain().focus().aiShorten(aiTextOptions).run(),
            },
            {
                key: aiSpellCheckerOptionKeys.rephrase,
                icon: <AIRephraseIcon color={iconColor} />,
                name: 'Rephrase Content',
                tooltip: 'Rephrases the selected text.',
                click: () => editor.chain().focus().aiRephrase(aiTextOptions).run(),
            },
            {
                key: aiSpellCheckerOptionKeys.simplify,
                icon: <AISummarizeIcon color={iconColor} />,
                name: 'Simplify Language',
                tooltip: 'Simplifies the selected text.',
                click: () => editor.chain().focus().aiSimplify(aiTextOptions).run(),
            },
            {
                key: aiSpellCheckerOptionKeys.keyPoints,
                icon: <UnorderedListIcon color={iconColor} />,
                name: 'Key Points',
                tooltip: 'Summarizes the selected text with bullet points.',
                click: () => editor.chain().focus().aiKeypoints(aiTextOptions).run(),
            },
            {
                key: aiSpellCheckerOptionKeys.toneOfVoice,
                type: 'group',
                icon: <AIToneOfVoiceIcon color={iconColor} />,
                name: 'Tone of Voice',
                tooltip: 'Adjusts the selected text to the specified tone of voice.',
                children: toneOptionsWithClick,
                click: () => {},
            },
        ],
        [aiTextOptions, editor, iconColor, languageCode, toneOptionsWithClick]
    );

    const [filteredItemsKeys, setFilteredItemsKeys] = useState(aiItems.map((item) => item.key));

    const debouncedFilter = debounce((items, input) => {
        const result = input
            ? filterOptions(
                  input,
                  items
                      .filter((item) => item.key !== aiSpellCheckerOptionKeys.aiLanguage)
                      .map((item) => ({
                          key: item.key,
                          name:
                              // Display the tone of voice option when input matches the child labels
                              item.key === aiSpellCheckerOptionKeys.toneOfVoice
                                  ? `${item.name} ${item.children
                                        .map((child) => child.label)
                                        .join(' ')}`
                                  : item.name,
                      }))
              )
            : items;

        setFilteredItemsKeys(result?.map((item) => item.key));
    }, 500);

    useEffect(() => {
        debouncedFilter(aiItems, editor.storage[nodeTypes.aiSpellChecker].customPromptText);

        return () => debouncedFilter.cancel();
    }, [aiItems, debouncedFilter, editor.storage]);

    const aiSpellCheckerItems = useMemo(() => {
        return aiItems
            .filter((aiItem) => filteredItemsKeys.includes(aiItem.key))
            .map((aiItem) => ({
                key: aiItem.key,
                children: aiItem.children || undefined,
                label: (
                    <Tooltip
                        overlayClassName={`${classes.tooltipOverlay} ${
                            classes.aiOptionTooltipOverlay
                        } ${getTooltipOverlayClass(darkMode)}`}
                        placement='top'
                        title={aiItem.tooltip}
                    >
                        <div className={classes.aiResponseOptionItem}>
                            {aiItem.icon}
                            <span>{aiItem.name}</span>
                        </div>
                    </Tooltip>
                ),
                onClick: () => {
                    const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

                    editor.commands.setActiveAiCommand(aiItem.key);
                    executeAiCommandWithHighlight(editor, from, to, aiItem.click);
                },
            }));
    }, [aiItems, darkMode, editor, filteredItemsKeys]);

    return {
        aiSpellCheckerItems,
    };
};

export default useAiSpellCheckerItems;

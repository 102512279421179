import { MAX_KEYWORD_LENGTH, MIN_KEYWORD_LENGTH } from '../../constants/constants';
import { adjustPatternForBrackets, getWordBoundaryRegex } from './regexHelpers';

export const isKeywordLengthValid = (keyword = '') => {
    const trimmedKeyword = keyword.trim();

    return (
        trimmedKeyword.length >= MIN_KEYWORD_LENGTH && trimmedKeyword.length <= MAX_KEYWORD_LENGTH
    );
};

export const getKeywordLengthErrorMessage = () => {
    return `Keyword must be between ${MIN_KEYWORD_LENGTH} and ${MAX_KEYWORD_LENGTH} characters long`;
};

export const getKeywordUrlErrorMessage = () => {
    return 'Please enter a valid URL (e.g. https://www.google.com)';
};

const getUniqueElements = (arrayItems = []) => {
    const seen = new Set();

    return arrayItems.filter((element) => {
        const uniqueKey = `${element[0]}-${element.index}`;
        if (seen.has(uniqueKey)) {
            return false;
        } else {
            seen.add(uniqueKey);
            return true;
        }
    });
};

export const collectAllKeywordMatches = (text = '', keywordItem = {}) => {
    if (!keywordItem || !text.length) {
        return [];
    }

    const escapedKeywordText = keywordItem.keyword.toLocaleLowerCase();
    const lowercasedText = text.toLocaleLowerCase();

    const matchedKeywordsArray = Array.from(
        lowercasedText.matchAll(keywordItem.regex ?? escapedKeywordText)
    );
    const originalMatches = Array.from(lowercasedText.matchAll(keywordItem.regex));
    const additionalMatches = Array.from(
        lowercasedText.matchAll(adjustPatternForBrackets(keywordItem.keyword, keywordItem.regex))
    );

    const allMatches = getUniqueElements(
        matchedKeywordsArray.concat(originalMatches.concat(additionalMatches))
    );

    return allMatches;
};

export const isKeywordFoundInText = (text = '', keywordItem = {}) => {
    if (!keywordItem || !text.length) {
        return false;
    }

    const keywordPattern = getWordBoundaryRegex(keywordItem.keyword.toLocaleLowerCase());
    const additionalPattern = adjustPatternForBrackets(
        keywordItem.keyword.toLocaleLowerCase(),
        keywordPattern
    );

    return keywordPattern.test(text) || additionalPattern.test(text);
};

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditorProvider } from '@tiptap/react';
import { debounce } from 'lodash';

import useAiResponseConfiguration from './hooks/useAiResponseConfiguration';
import useEditorEventHandlers from './hooks/useEditorEventHandlers';
import useUpdateEditorContent from './hooks/useUpdateEditorContent';
import { configureCustomKeyboardShortcuts } from './utils/customKeyboardShortcuts';

import { editorAutoSaveDelay } from '../../../constants/contentOptimizer';
import { nodeTypes } from './constants/nodeTypes';

import { HighlightedKeywordContext } from '../../pages/contentOptimizer/contentPage/content/optimizerTab/tiptapEditor/TipTapEditorWrapper';

import TipTapEditorSettings from './TipTapEditorSettings';
import DraggableComponent from './components/DraggableComponent';
import EditorToolbar from './toolbar/EditorToolbar';
import EditLinkContent from './toolbar/buttons/content/EditLinkContent';

import { CustomAi } from './extensions/aiExtensions';
import { HighlightKeywordsExtension } from './extensions/customExtensions';
import { tiptapEditorExtensions } from './extensions/tiptapEditorExtensions';

import { updateCurrentEditorText } from './store/tiptapEditor.actions';
import {
    selectAIStateAccepted,
    selectAIStateLoading,
    selectCurrentEditorContent,
    selectEditorPreviewVersion,
    selectGeneratedAIToken,
} from './store/tiptapEditor.selectors';

import './TipTapEditor.scss';

const TipTapEditor = () => {
    const dispatch = useDispatch();

    const editorRef = useRef(null);
    const editorRootRef = useRef(null);

    const mappedKeywords = React.useContext(HighlightedKeywordContext);

    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);
    const aiLoading = useSelector(selectAIStateLoading);

    const generatedAIToken = useSelector(selectGeneratedAIToken);
    const aiAccepted = useSelector(selectAIStateAccepted);

    const [handleUpdateEditor] = useUpdateEditorContent();
    const { handleAiLoading, handleAiChunk, handleAiSuccess, handleAiError } =
        useAiResponseConfiguration();

    useEditorEventHandlers(editorRef, editorRootRef);

    const handleEditorReady = (editorData) => {
        const { editor } = editorData;

        if (editor) {
            editorRef.current = editor;

            editor.options.editorProps.handleKeyDown = (_, event) => {
                configureCustomKeyboardShortcuts(editor, event);
            };
            editor.commands.setKeywordList(mappedKeywords || []);
            editor.commands.resetAiSpellCheckerStorage();

            const newEditorContent =
                editorPreviewVersion && editorPreviewVersion.content?.length > 0
                    ? editorPreviewVersion.content.slice(1, -1)
                    : currentEditorContent;

            if (editor.getHTML() !== newEditorContent) {
                editor.commands.setContent(newEditorContent);

                const editorText = editor.getText();
                dispatch(updateCurrentEditorText(editorText));
            }
        }
    };

    const handleEditorChange = debounce((editorOnChangeData) => {
        const { editor } = editorOnChangeData;

        if (editor.storage[nodeTypes.aiSpellChecker].spellCheckerVisible) {
            return;
        }

        const updatedEditorData = editor?.getHTML();
        const updatedEditorText = editor?.getText() || '';

        dispatch(updateCurrentEditorText(updatedEditorText));
        handleUpdateEditor(updatedEditorData, updatedEditorText);
    }, editorAutoSaveDelay);

    const handleSelectionUpdate = ({ editor }) => {
        if (
            !aiAccepted &&
            !editor.storage[nodeTypes.aiSpellChecker].regenerateOptionEnabled &&
            editor.storage[nodeTypes.aiSpellChecker].response
        ) {
            const selectionEndPosition = editor.storage[nodeTypes.aiSpellChecker].selectedRange.to;

            editor.commands.setTextSelection({
                from: selectionEndPosition,
                to: selectionEndPosition,
            });
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.setEditable(!aiLoading);
        }
    }, [aiLoading]);

    return (
        <div ref={editorRootRef}>
            <EditorProvider
                slotBefore={<EditorToolbar />}
                extensions={[
                    ...tiptapEditorExtensions,
                    HighlightKeywordsExtension.configure({
                        className: 'highlight-keyword',
                    }),
                    CustomAi.configure({
                        appId: process.env.REACT_APP_TIPTAP_AI_APP_ID || '',
                        token: generatedAIToken || '',
                        autocompletion: true,
                        onLoading: handleAiLoading,
                        onChunk: handleAiChunk,
                        onSuccess: handleAiSuccess,
                        onError: handleAiError,
                    }),
                ]}
                onCreate={handleEditorReady}
                onUpdate={handleEditorChange}
                onSelectionUpdate={handleSelectionUpdate}
            >
                <TipTapEditorSettings />
                <EditLinkContent />
                <DraggableComponent />
            </EditorProvider>
        </div>
    );
};

export default TipTapEditor;

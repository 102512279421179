import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { Tag, Tooltip, Typography } from 'antd';

import { isMainKeywordSource } from '../../../../../../../../utils/helpers/getSortedOptimizerKeywords';
import { getThemeColorClass } from '../../../../../../../../utils/helpers/themeHelpers';
import { useOpen } from '../../../../../../../../utils/hooks/useOpen';

import {
    contentOptimizerKeywordSource,
    queryKeywordText,
} from '../../../../../../../../constants/contentOptimizerKeywordSource';
import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { selectContentOptimizerViewOnlyMode } from '../../../../../viewOnly/store/viewOnlySettings.selectors';

import OptimizerKeywordExamplesModal from './examplesModal/OptimizerKeywordExamplesModal';
import KeywordBlockPopconfirm from './KeywordBlockPopconfirm';
import KeywordDeletePopconfirm from './KeywordDeletePopconfirm';

const { Title, Link } = Typography;

const classes = {
    dropdownContentWrapper: 'dropdown-with-extra-content-wrapper keyword-info-wrapper',
    textAccent: 'text-accent',
    textSmall: 'text-small',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    link: 'link-hover-highlight',
    keywordExamplesButtonWrapper: 'keyword-examples-button d-flex justify-content-between',
    textDotted: 'text-decoration-dotted',
};

const OptimizerKeywordDropdownContent = forwardRef(({ keywordItem }, dropdownRef) => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);

    const { isOpen: isModalOpen, handleOpen: handleModalOpen } = useOpen();

    const getAverageUsageText = (typicalUses) => {
        if (!typicalUses || Math.max(...typicalUses) < 1) {
            return 'n/a';
        }

        const usesFrom = typicalUses[0] ?? 0;
        const usesTo = typicalUses[1] ?? 0;

        return `${usesFrom === usesTo ? usesFrom : `${usesFrom}-${usesTo}`} time${
            usesTo === 1 ? '' : 's'
        }`;
    };

    return (
        <div className={classes.dropdownContentWrapper} ref={dropdownRef}>
            <Title level={5}>{keywordItem?.keyword}</Title>
            {keywordItem?.source === contentOptimizerKeywordSource.custom && (
                <Tag color='processing' key={keywordItem?.keyword}>
                    Custom
                </Tag>
            )}
            {keywordItem?.source === contentOptimizerKeywordSource.main ? (
                <Typography className='mb-1'>
                    <Tooltip
                        placement='top'
                        title={`The primary or exact match keyword is placed at the top of our Relevance list by default. It is recommended to use it naturally in your content but Google will still recognize variations of the primary keyword when contextually relevant.`}
                        overlayClassName={`${classes.tooltipOverlay}
                                ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <span className={classes.textDotted}>{queryKeywordText}</span>
                    </Tooltip>
                </Typography>
            ) : null}
            <Typography className='mb-1'>
                <Tooltip
                    placement='top'
                    title={
                        'Measures the Relevance of a keyword based on relevance and frequency on top-ranked pages, where 1/10 is least important and 10/10 is most important.'
                    }
                    overlayClassName={`${classes.tooltipOverlay} ${
                        darkMode ? classes.tooltipOverlayDark : ''
                    }`}
                >
                    <span className={classes.textDotted}>Relevance:</span>{' '}
                    {keywordItem?.source === contentOptimizerKeywordSource.custom ? (
                        <span>n/a</span>
                    ) : (
                        <>
                            <span className={keywordItem?.importance && classes.textAccent}>
                                {keywordItem?.importance || '-'}
                            </span>
                            <span className={classes.textSmall}>/10</span>
                        </>
                    )}
                </Tooltip>
            </Typography>
            {keywordItem?.heading_presence ? (
                <Typography className='mb-1'>
                    <Tooltip
                        placement='top'
                        title={
                            'Measures how often a keyword appears in header tags (h1, h2, etc), where 1/10 is less common and 10/10 is frequently used in headings.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                    ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <span className={classes.textDotted}>Heading:</span>{' '}
                        <span className={classes.textAccent}>{keywordItem?.heading_presence}</span>
                        <span className={classes.textSmall}>/10</span>
                    </Tooltip>
                </Typography>
            ) : null}
            <Typography>
                <Tooltip
                    placement='top'
                    title={
                        'Average number of times a keyword is used on top-ranked pages. Average usage range is just a guide, aim to include the keyword at least once in your content.'
                    }
                    overlayClassName={`${classes.tooltipOverlay}
                                    ${darkMode ? classes.tooltipOverlayDark : ''}`}
                >
                    <span className={classes.textDotted}>Average usage:</span>{' '}
                    {getAverageUsageText(keywordItem?.typical_uses)}
                </Tooltip>
            </Typography>
            <Typography>Current usage: {keywordItem?.uses || '-'}</Typography>
            <div className={classes.keywordExamplesButtonWrapper}>
                <Link
                    className={`${classes.link} ${getThemeColorClass(darkMode)}`}
                    underline
                    onClick={handleModalOpen}
                >
                    Examples
                </Link>

                <div className='d-flex'>
                    {!viewOnlyMode && !isMainKeywordSource(keywordItem?.source) && (
                        <>
                            <KeywordBlockPopconfirm keyword={keywordItem?.keyword} />
                            <KeywordDeletePopconfirm
                                keywordsToDelete={
                                    keywordItem?.keyword ? [keywordItem?.keyword] : []
                                }
                            >
                                <Link
                                    underline
                                    className={`${classes.link} ${getThemeColorClass(darkMode)}`}
                                >
                                    Delete
                                </Link>
                            </KeywordDeletePopconfirm>
                        </>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <OptimizerKeywordExamplesModal
                    keywordItem={keywordItem}
                    isOpen={isModalOpen}
                    handleOpen={handleModalOpen}
                />
            )}
        </div>
    );
});

export default OptimizerKeywordDropdownContent;

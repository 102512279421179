import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import MultipleCreateArea from '../inputs/MultipleCreateArea';
import KeywordSelect from '../selects/KeywordSelect';
import NoCreditsModal from './NoCreditsModal';
import RunOptimizerButton from './RunOptimizerButton';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import {
    getKeywordLengthErrorMessage,
    getKeywordUrlErrorMessage,
    isKeywordLengthValid,
} from '../../../utils/helpers/keywordHelpers';
import { openNotification } from '../../../utils/helpers/openNotification';
import { useOpen } from '../../../utils/hooks/useOpen';

import { countries, defaultCountry, featuredCountries } from '../../../constants/countries';
import { defaultLanguage, featuredLanguages, languages } from '../../../constants/languages';
import { errorNotificationMessage, notificationType } from '../../../constants/notificationType';
import { contentBriefPathes } from '../../../constants/queryPathes';
import { urlPattern } from '../../../constants/regularExpression';
import { statusCodes } from '../../../constants/statusCodes';
import { subscriptionLimits } from '../../../constants/subscriptionLimits';

import { increaseSubscriptionLimits } from '../../pages/account/billing/store/billingSettings.actions';
import {
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';
import { selectIsImportURLActive } from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.selectors';

import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import './CreateNew.scss';

const classes = {
    createAreaWrapper: 'create-area-wrapper create-multiple-section',
    optionsWrapper:
        'options-wrapper keyword-header-wrapper w-100 d-flex justify-content-between',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
    runBriefBtn: 'run-brief-btn',
};

const CreateMultipleSection = ({ getOptimizers, runBtnText, handleCreateMultiple }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const userLanguagePreference =
        useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;
    const isImportURLActive = useSelector(selectIsImportURLActive);

    const [contentBriefData, setContentBriefData] = useState({
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
        keywords: '',
        urls: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [keywordsArray, setKeywordsArray] = useState([]);
    const [urlsArray, setUrlsArray] = useState([]);
    const [isKeywordsInputError, setKeywordsIsInputError] = useState(false);
    const [isUrlInputError, setUrlIsInputError] = useState(false);

    const { isOpen, handleOpen } = useOpen();

    useEffect(() => {
        if (contentBriefData.keywords?.includes(',')) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [
                        'Please, use new line for each keyword instead of comma',
                    ],
                },
            ]);
        } else if (
            contentBriefData.keywords
                ?.split('\n')
                ?.filter((item) => item !== '').length >
                optimizersLimit + optimizersAdded - optimizersUsed &&
            optimizersLimit + optimizersAdded - optimizersUsed !== 0
        ) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You have reached the limit of credits'],
                },
            ]);
        } else if (contentBriefData.keywords?.split('\n').length > 100) {
            setKeywordsIsInputError(true);
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You can add up to 100 keywords'],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);
        }

        if (contentBriefData.urls?.includes(',')) {
            form.setFields([
                {
                    name: 'urls',
                    errors: [
                        'Please, use new line for each url instead of comma',
                    ],
                },
            ]);

            setUrlIsInputError(true);
        } else {
            form.setFields([
                {
                    name: 'urls',
                    errors: [],
                },
            ]);

            setUrlIsInputError(false);
        }

        // eslint-disable-next-line
    }, [contentBriefData]);

    useEffect(() => {
        if (contentBriefData.keywords) {
            setKeywordsArray(contentBriefData.keywords.split('\n'));
        }

        if (contentBriefData.urls) {
            setUrlsArray(contentBriefData.urls.split('\n'));
        }
    }, [contentBriefData]);

    const handleChangeKeywords = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            keywords: value,
        }));

        setKeywordsIsInputError(false);
        setUrlIsInputError(false);
    };

    const handleChangeUrls = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            urls: value,
        }));

        setKeywordsIsInputError(false);
        setUrlIsInputError(false);
    };

    const handleChangeLanguage = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const handleChangeLocation = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            country_code: value,
        }));
    };

    const validateRequestKeywords = (keywordList = []) => {
        let keywordFieldIsCorrect = true;

        if (!keywordList.length) {
            setKeywordsIsInputError(true);

            return false;
        }

        keywordList.forEach((keyword) => {
            if (!isKeywordLengthValid(keyword)) {
                setKeywordsIsInputError(true);
                form.setFields([
                    {
                        name: 'keywords',
                        errors: [getKeywordLengthErrorMessage()],
                    },
                ]);
                keywordFieldIsCorrect = false;
            }
        });

        return keywordFieldIsCorrect;
    };

    const handleRunBrief = () => {
        const parsedKeywords = contentBriefData.keywords
            .split('\n')
            .filter((item) => item && item?.trim() !== '');

        if (
            form.getFieldsError().some((item) => item.errors.length > 0) ||
            optimizersUsed >= optimizersLimit + optimizersAdded ||
            !optimizersLimit
        ) {
            handleOpen();
        } else if (
            contentBriefData.urls?.split('\n').length >
            contentBriefData.keywords?.split('\n').length
        ) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['Please add the same number of keywords and URLs'],
                },
            ]);

            setKeywordsIsInputError(true);
            setUrlIsInputError(true);
        } else if (
            !contentBriefData?.urls
                ?.split('\n')
                ?.every((link) => link.match(urlPattern) || link === '')
        ) {
            form.setFields([
                {
                    name: 'urls',
                    errors: [getKeywordUrlErrorMessage()],
                },
            ]);

            setKeywordsIsInputError(true);
            setUrlIsInputError(true);
        } else if (parsedKeywords?.length > 0 && validateRequestKeywords(parsedKeywords)) {
            setIsLoading(true);

            const contentBriefModel = {
                country_code: contentBriefData.country_code,
                language_code: contentBriefData.language_code,
                keywords: parsedKeywords.join(','),
                url: contentBriefData.urls.split('\n').join(','),
            };

            axiosAPI
                .post(
                    contentBriefPathes.createContentBrief,
                    {
                        ...contentBriefModel,
                        user_created_at_time: new Date().toISOString(),
                    },
                    {
                        ...getAxiosHeaders(),
                        params: {
                            get_optimizers: getOptimizers,
                        },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        handleCreateMultiple(result?.data?.reverse());
                        dispatch(
                            increaseSubscriptionLimits({
                                limit: subscriptionLimits.optimizersUsed,
                                value: result?.data?.length,
                            })
                        );
                        setContentBriefData((prev) => ({
                            ...prev,
                            keywords: '',
                            urls: '',
                        }));
                        setKeywordsArray([]);
                        setUrlsArray([]);
                        form.resetFields();
                    }
                })
                .catch(({ response }) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        response?.data?.message || errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setKeywordsIsInputError(true);
        }
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                className={classes.createAreaWrapper}
                initialValues={contentBriefData}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label='Keywords' name='keywords'>
                            <MultipleCreateArea
                                isInputError={isUrlInputError || isKeywordsInputError}
                                placeholder='Enter multiple keywords or phrases separated on a new line by pressing Enter — one keyword per line, maximum 100 (e.g. best running shoes)…'
                                handleChange={handleChangeKeywords}
                                value={contentBriefData.keywords}
                                keywordArray={keywordsArray}
                            />
                        </Form.Item>
                    </Col>
                    {isImportURLActive && (
                        <Col span={12}>
                            <Form.Item label='URLs (optional)' name='urls'>
                                <MultipleCreateArea
                                    isInputError={isUrlInputError || isKeywordsInputError}
                                    placeholder='Enter associated URL for each keyword to import URL content — one URL per line, maximum 100 (e.g. https://www.domain.com/best-running-shoes)…'
                                    handleChange={handleChangeUrls}
                                    value={contentBriefData.urls}
                                    keywordArray={urlsArray}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <div className={classes.optionsWrapper}>
                    <div className='d-flex'>
                        <div className={classes.keywordLocationSelect}>
                            <KeywordSelect
                                placeholder='Select Location'
                                options={getArrayOptionsFromObject({
                                    values: countries,
                                    featured: featuredCountries,
                                })}
                                defaultValue={contentBriefData.country_code}
                                onChange={handleChangeLocation}
                            />
                        </div>
                        <div className={classes.keywordLanguageSelect}>
                            <KeywordSelect
                                placeholder='Select Language'
                                options={getArrayOptionsFromObject({
                                    values: languages,
                                    featured: featuredLanguages,
                                })}
                                defaultValue={contentBriefData.language_code}
                                onChange={handleChangeLanguage}
                            />
                        </div>
                        <Form.Item shouldUpdate className={classes.runBriefBtn}>
                            {() => (
                                <RunOptimizerButton
                                    isLoading={isLoading}
                                    runBtnText={runBtnText}
                                    handleRunBrief={handleRunBrief}
                                    isDisabled={isKeywordsInputError || isUrlInputError}
                                />
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <NoCreditsModal isOpen={isOpen} handleOpen={handleOpen} />
        </>
    );
};

export default CreateMultipleSection;

import DOMPurify from 'dompurify';
import { decode } from 'html-entities';
import HtmlDiff from 'htmldiff-js';
import { DOMSerializer } from 'prosemirror-model';

import { spellingGrammarCongratsMessages } from '../constants/spellingGrammarCongratsMessages';

import {
    removeEmptyTags,
    removeExtraSpacesBetweenRootTags,
} from '../../../../utils/helpers/htmlHelpers';
import { convertHtmlToText } from './wordCountHelpers';

/**
 * Picks a random congratulations message from the array.
 * @returns {string} - A random congratulatory message.
 */
const getRandomCongratsMessage = () => {
    const randomIndex = Math.floor(Math.random() * spellingGrammarCongratsMessages.length);
    const randomMessage = spellingGrammarCongratsMessages[randomIndex];

    return `<p><strong>${randomMessage.title}</strong> ${randomMessage.message}</p>`;
};

/**
 * Cleans the input HTML string by removing unwanted tags and decoding HTML entities.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
const cleanHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Remove <mark> tags as they are in selected text and not part of the correction.
    const regex = /<mark[^>]*>|<\/mark>/g;
    const cleanedHtml = doc.body.innerHTML.replace(regex, '');

    // Decode HTML entities like &nbsp;
    return decode(cleanedHtml);
};

/**
 * Highlights differences between two HTML strings.
 * @param {string} originalHtml - The original HTML string.
 * @param {string} correctedHtml - The corrected HTML string.
 * @returns {string} - The HTML string with highlighted differences.
 */
export const highlightHtmlDifferences = (editor, originalHtml, correctedHtml) => {
    const cleanedOriginal = cleanHtml(originalHtml);
    const cleanedCorrected = cleanHtml(correctedHtml);

    if (convertHtmlToText(cleanedOriginal) === convertHtmlToText(cleanedCorrected)) {
        editor.commands.setSpellingErrorsDetected(false);

        return getRandomCongratsMessage();
    }

    editor.commands.setSpellingErrorsDetected(true);

    return HtmlDiff.execute(cleanedOriginal, cleanedCorrected);
};

/**
 * Gets the HTML content between two positions in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} start - The start position.
 * @param {number} end - The end position.
 * @returns {string} - The HTML content between the positions.
 */
export const getHTMLBetweenRange = (editor, start, end) => {
    const { state } = editor;
    const { doc } = state;

    const fromPos = doc.resolve(start);
    const toPos = doc.resolve(end);

    const slice = doc.slice(fromPos.pos, toPos.pos);
    const serializer = DOMSerializer.fromSchema(editor.schema);

    const div = document.createElement('div');
    div.appendChild(serializer.serializeFragment(slice.content));

    return div.innerHTML;
};

/**
 * Formats generated by the AI response HTML string to avoid corner cases.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
export const formatAIResponseHtml = (html) => {
    // Corrects the HTML structure to avoid corner cases
    const sanitizedHtml = DOMPurify.sanitize(html);

    // Cleans the HTML by removing empty tags that may have been generated after sanitization
    const cleanedHtml = removeExtraSpacesBetweenRootTags(removeEmptyTags(sanitizedHtml));

    return cleanedHtml;
};

/**
 * Extracts the body content from an HTML string.
 * @param {string} html - The HTML string.
 * @returns {string} - The body content.
 */
export const extractBodyContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    return doc.body.innerHTML;
};

/**
 * Gets the correct end position of the cursor in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} pos - The current cursor position.
 * @returns {number} - The correct end position.
 */
export const getCorrectEndPosition = (editor, pos) => {
    const resolvedPosition = editor.state.doc.resolve(pos);
    const isCursorAtNodeStart = resolvedPosition.nodeBefore === null;

    return isCursorAtNodeStart ? pos - 1 : pos;
};

/**
 * Gets the correct start position of the cursor in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} pos - The current cursor position.
 * @returns {number} - The correct start position.
 */
export const getCorrectStartPosition = (editor, pos) => {
    const resolvedPosition = editor.state.doc.resolve(pos);
    const isCursorAtNodeEnd = resolvedPosition.nodeAfter === null;

    return isCursorAtNodeEnd ? pos + 1 : pos;
};

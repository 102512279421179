import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextSelection } from '@tiptap/pm/state';
import { useCurrentEditor } from '@tiptap/react';
import { Tooltip } from 'antd';

import AIInsertBelowIcon from '../../../../resources/icons/ai/AIInsertBelowIcon';
import AIReplaceSelectionIcon from '../../../../resources/icons/ai/AIReplaceSelectionIcon';
import AITryAgainIcon from '../../../../resources/icons/ai/AITryAgainIcon';
import TrashIcon from '../../../../resources/icons/TrashIcon';

import {
    aiSpellCheckerOptionKeys,
    EDITOR_AI_CREDITS_REQUIRED,
} from '../constants/aiEditorConstants';
import { nodeTypes } from '../constants/nodeTypes';

import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import {
    clearFirstNodeIfEmptyTransaction,
    createRemoveHighlightTransaction,
    createTransaction,
    deleteNode,
    executeAiCommandWithHighlight,
    insertHtmlContentTransaction,
} from '../utils/editorTransactionHelpers';
import useEditorAiCredits from './useEditorAiCredits';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectContentOptimizerViewOnlyMode } from '../../../pages/contentOptimizer/viewOnly/store/viewOnlySettings.selectors';
import { resetAIState } from '../store/tiptapEditor.actions';

const classes = {
    aiCreditsLabel: 'ai-credits',
    optionLabel: 'w-100 d-flex align-items-center',

    tooltipOverlay: 'tooltip-overlay toolbar-tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const aiResponseOptionsKeys = {
    accept: 'accept',
    insertBelow: 'insertBelow',
    tryAgain: 'tryAgain',
    discard: 'discard',
};

const useAiResponseItems = (from, to, response) => {
    const dispatch = useDispatch();
    const { editor } = useCurrentEditor();

    const iconColor = useIconColor();
    const { hasSufficientAiCredits } = useEditorAiCredits();

    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const darkMode = useSelector(selectDarkMode);

    const handleAction = useCallback((callback) => {
        return (...args) => {
            try {
                callback(...args);
            } catch (error) {
                openBasicErrorNotification();
            }
        };
    }, []);

    //Note that the order of the calls is important for each of the following functions
    const resetNode = useCallback(() => {
        dispatch(resetAIState());
        deleteNode(editor, nodeTypes.aiSpellChecker);
        createRemoveHighlightTransaction(editor, from, to);
        editor.commands.resetAiSpellCheckerStorage();
    }, [dispatch, editor, from, to]);

    const setSelection = useCallback(
        (from, to) => {
            editor.commands.setTextSelection({ from, to });
            editor.commands.focus();
        },
        [editor]
    );

    const handleAccept = useCallback(() => {
        resetNode();
        createTransaction(
            editor,
            (tr) => {
                // Delete the selected content using deleteSelection() to remove entire nodes, as using deleteRange() on non-contiguous selections (e.g., list items) can leave empty nodes.
                tr.setSelection(TextSelection.create(tr.doc, from, to));
                tr.deleteSelection();

                const updatedFrom = tr.selection.from;

                insertHtmlContentTransaction(tr, updatedFrom, response);

                const updatedTo = tr.selection.to;

                clearFirstNodeIfEmptyTransaction(tr, updatedFrom, updatedTo);
            },
            true
        );

        editor.commands.focus();
    }, [editor, from, to, response, resetNode, setSelection]);

    const handleInsertBelow = useCallback(() => {
        resetNode();
        editor.chain().focus().insertContentAt(to, response).run();

        const endPosition = editor.view.state.selection.to;
        setSelection(to, endPosition);
    }, [editor, to, response, resetNode, setSelection]);

    const handleReject = useCallback(() => {
        resetNode();
        setSelection(from, to);
    }, [from, to, resetNode, setSelection]);

    const handleRegenerate = useCallback(() => {
        editor.commands.setRegenerateOptionEnabled(true);
        executeAiCommandWithHighlight(editor, from, to, () => {
            editor.commands.aiRegenerate();
        });
    }, [editor, from, to]);

    const aiCreditsLabel = useMemo(
        () =>
            !viewOnlyMode && (
                <span className={classes.aiCreditsLabel}>
                    ({EDITOR_AI_CREDITS_REQUIRED} AI credit)
                </span>
            ),
        [viewOnlyMode]
    );

    const activeAiCommand = editor.storage[nodeTypes.aiSpellChecker].activeAiCommand;
    const spellingErrorsDetected = editor.storage[nodeTypes.aiSpellChecker].spellingErrorsDetected;

    const aiResponseOptions = useMemo(() => {
        const options = [
            {
                key: aiResponseOptionsKeys.accept,
                label: 'Accept & replace selection',
                icon: <AIReplaceSelectionIcon color={iconColor} />,
                onClick: handleAction(handleAccept),
                requireCredit: false,
            },
            {
                key: aiResponseOptionsKeys.insertBelow,
                label: 'Insert below selection',
                icon: <AIInsertBelowIcon color={iconColor} />,
                onClick: handleAction(handleInsertBelow),
                requireCredit: false,
            },
            {
                key: aiResponseOptionsKeys.tryAgain,
                label: 'Try again',
                icon: <AITryAgainIcon color={iconColor} />,
                onClick: handleAction(handleRegenerate),
                requireCredit: true,
            },
            {
                key: aiResponseOptionsKeys.discard,
                label: 'Discard',
                icon: <TrashIcon color={iconColor} />,
                onClick: handleAction(handleReject),
                requireCredit: false,
            },
        ];

        if (
            activeAiCommand === aiSpellCheckerOptionKeys.spellingGrammar &&
            !spellingErrorsDetected
        ) {
            return options.filter((option) => option.key === aiResponseOptionsKeys.discard);
        }

        return options;
    }, [
        activeAiCommand,
        spellingErrorsDetected,
        iconColor,
        handleAction,
        handleAccept,
        handleInsertBelow,
        handleRegenerate,
        handleReject,
    ]);

    const aiResponseItems = useMemo(() => {
        return aiResponseOptions.map((option) => {
            if (option.requireCredit) {
                const { requireCredit, ...aiOption } = option;

                return {
                    ...aiOption,
                    label: (
                        <Tooltip
                            overlayClassName={`${classes.tooltipOverlay} ${
                                darkMode ? classes.tooltipOverlayDark : ''
                            }`}
                            placement='top'
                            title={!hasSufficientAiCredits ? 'Not enough AI credits' : ''}
                        >
                            <div className={classes.optionLabel}>
                                {aiOption.label}
                                {aiCreditsLabel}
                            </div>
                        </Tooltip>
                    ),
                    disabled: !hasSufficientAiCredits,
                };
            }

            return option;
        });
    }, [hasSufficientAiCredits, aiResponseOptions, aiCreditsLabel, darkMode]);

    return {
        aiResponseItems,
        handleAccept,
        handleReject,
        handleInsertBelow,
        handleRegenerate,
    };
};

export default useAiResponseItems;

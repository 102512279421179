import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Typography } from 'antd';

import { setUserData } from '../../../../../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectUserData,
} from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { FILTERED_TERMS } from '../../../../../../../../constants/constants';
import { notificationType } from '../../../../../../../../constants/notificationType';
import { organizationPathes } from '../../../../../../../../constants/queryPathes';

import { axiosAPI } from '../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../../../utils/helpers/openBasicErrorNotification';
import { openNotification } from '../../../../../../../../utils/helpers/openNotification';
import { getThemeColorClass } from '../../../../../../../../utils/helpers/themeHelpers';

const { Link } = Typography;

const classes = {
    blockLink: 'block-link',
    blockPopconfirm: 'block-popconfirm',
    blockPopconfirmDark: 'block-popconfirm-dark',
    link: 'link-hover-highlight',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const KeywordBlockPopconfirm = ({ keyword }) => {
    const dispatch = useDispatch();

    const user = useSelector(selectUserData);
    const darkMode = useSelector(selectDarkMode);

    const [isLoading, setIsLoading] = useState(false);

    const filteredTerms = user?.organization?.settings?.[FILTERED_TERMS];
    const isKeywordBlocked = filteredTerms?.some(
        (x) => x?.term && x.term?.toLowerCase() === keyword?.toLowerCase()
    );

    const popconfirmTitle = !isKeywordBlocked
        ? 'Add this keyword to your filter list in Settings and block it from appearing in future Optimizers?'
        : 'Remove this keyword from your filter list in Settings and have it appear in future Optimizers?';

    const handleBlockKeyword = () => {
        setIsLoading(true);

        const newSetting = !isKeywordBlocked
            ? {
                  settings: {
                      [FILTERED_TERMS]: [...filteredTerms, { term: keyword }],
                  },
              }
            : {
                  settings: {
                      [FILTERED_TERMS]: [
                          ...filteredTerms?.filter(
                              (x) => x?.term && x.term?.toLowerCase() !== keyword?.toLowerCase()
                          ),
                      ],
                  },
              };

        axiosAPI
            .patch(`${organizationPathes.updateOrganization}/${user.organization.id}`, newSetting, {
                ...getAxiosHeaders(),
            })
            .then((res) => {
                if (res?.data?.settings) {
                    dispatch(
                        setUserData({
                            ...user,
                            organization: {
                                ...user.organization,
                                settings: res.data.settings,
                            },
                        })
                    );

                    openNotification(
                        notificationType.success,
                        'Success',
                        !isKeywordBlocked
                            ? 'Keyword has been successfully blocked'
                            : 'Keyword has been successfully unblocked'
                    );
                } else {
                    openBasicErrorNotification();
                }
            })
            .catch((error) => openBasicErrorNotification())
            .finally(() => setIsLoading(false));
    };

    return (
        <Popconfirm
            placement='topRight'
            title={popconfirmTitle}
            onConfirm={handleBlockKeyword}
            okText='Yes'
            cancelText='Cancel'
            overlayClassName={`${classes.blockPopconfirm} ${
                darkMode ? classes.blockPopconfirmDark : ''
            }`}
        >
            <Link
                className={`${classes.link} ${classes.blockLink} ${getThemeColorClass(darkMode)}`}
                underline
                disabled={isLoading}
            >
                {isKeywordBlocked ? 'Unblock' : 'Block'}
            </Link>
        </Popconfirm>
    );
};

export default KeywordBlockPopconfirm;

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Menu } from 'antd';

import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';
import useAiSpellCheckerItems from '../../hooks/useAiSpellCheckerItems';
import { createRemoveHighlightTransaction, deleteNode } from '../../utils/editorTransactionHelpers';

import { nodeTypes } from '../../constants/nodeTypes';
import { AI_BUTTON_CLASS } from '../../toolbar/buttons/AISection';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setAISpellCheckerVisible } from '../../store/tiptapEditor.actions';
import { selectAIStateLoading } from '../../store/tiptapEditor.selectors';

import AiCustomPrompt from './AiCustomPrompt';

const AI_PROMPT_WRAPPER_CLASS = 'ai-prompt-wrapper';
const AI_ACTIONS_WRAPPER_CLASS = 'ai-actions-wrapper';

const classes = {
    actionsWrapper: `${AI_ACTIONS_WRAPPER_CLASS} positioned-container`,
    promptWrapper: `${AI_PROMPT_WRAPPER_CLASS} positioned-container`,
};

const AiActionsPanel = () => {
    const dispatch = useDispatch();

    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);
    const aiLoading = useSelector(selectAIStateLoading);

    const { aiSpellCheckerItems } = useAiSpellCheckerItems();

    const hideSpellChecker = useCallback(() => {
        if (aiLoading) {
            return;
        }

        const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

        dispatch(setAISpellCheckerVisible(false));
        deleteNode(editor, nodeTypes.aiSpellChecker);
        createRemoveHighlightTransaction(editor, from, to);

        editor.commands.resetAiSpellCheckerStorage();
        editor.commands.setTextSelection({ from, to });
        editor.commands.focus();
    }, [aiLoading, dispatch, editor]);

    const handleClickOutside = useCallback(
        (event) => {
            if (
                !event.target.closest(`.${AI_BUTTON_CLASS}`) &&
                !event.target.closest(`.${AI_PROMPT_WRAPPER_CLASS}`) &&
                !event.target.closest(`.${AI_ACTIONS_WRAPPER_CLASS}`)
            ) {
                hideSpellChecker();
            }
        },
        [hideSpellChecker]
    );

    const handleKeyDown = useCallback(
        (event) => {
            if (!event.target.closest(`.${AI_PROMPT_WRAPPER_CLASS}`)) {
                hideSpellChecker();
            }
        },
        [hideSpellChecker]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [editor, handleClickOutside, handleKeyDown]);

    if (aiLoading) {
        return null;
    }

    return (
        <>
            <div className={`${classes.promptWrapper} ${getThemeColorClass(darkMode)}`}>
                <AiCustomPrompt />
            </div>
            {aiSpellCheckerItems.length ? (
                <div className={`${classes.actionsWrapper} ${getThemeColorClass(darkMode)}`}>
                    <Menu
                        items={aiSpellCheckerItems}
                        getPopupContainer={(node) => node.parentNode}
                        expandIcon={<SiderChevronUpIcon />}
                    />
                </div>
            ) : null}
        </>
    );
};

export default AiActionsPanel;

import React, { useMemo } from 'react';

import { WarningFilled } from '@ant-design/icons';
import { Typography } from 'antd';

import { countWords } from '../../utils/wordCountHelpers';

const classes = {
    aiResponseFooter: 'ai-response-footer',
    wordCountLabel: 'word-count-label',
};

const AiResponseFooter = ({ response }) => {
    const responseWordCount = useMemo(() => {
        if (!response) return 0;

        return countWords(response, true);
    }, [response]);

    return (
        <div className={classes.aiResponseFooter}>
            <Typography.Text>
                <WarningFilled />
                Check AI-generated content. It can make mistakes or replace important NLP keywords.
            </Typography.Text>
            <Typography.Text className={classes.wordCountLabel}>
                {responseWordCount} words
            </Typography.Text>
        </div>
    );
};

export default AiResponseFooter;

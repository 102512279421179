import { Extension, getAttributes } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import ImageResize from 'tiptap-extension-resize-image';

import { collectAllKeywordMatches } from '../../../../utils/helpers/keywordHelpers';

import { alignments } from '../constants/alignments';
import { ELEMENT_NODE } from '../constants/customClipboardConstants';

const getImageWidthForAttribute = (elementWidth = 0) => {
    let numberedWidth = parseInt(elementWidth, 10);

    if (numberedWidth > 0) {
        if (`${elementWidth}`.includes('%')) {
            if (numberedWidth >= 100) {
                return `100%`;
            }

            const fullWidth = 665;
            numberedWidth = (numberedWidth * fullWidth) / 100;
        }

        return `${numberedWidth}px`;
    }

    return '100%';
};

export const highlighterPluginKey = new PluginKey('highlighterPlugin');

export const CustomLinkExtension = Link.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            openOnClick: false,
        };
    },
    addProseMirrorPlugins() {
        const plugins = this.parent?.() || [];

        const ctrlClickHandler = new Plugin({
            key: new PluginKey('handleControlClick'),
            props: {
                handleClick(view, pos, event) {
                    const attrs = getAttributes(view.state, 'link');
                    const link = event.target?.closest('a');

                    const keyPressed = event.ctrlKey || event.metaKey;

                    if (keyPressed && link && attrs.href) {
                        window.open(attrs.href, attrs.target);

                        return true;
                    }

                    return false;
                },
            },
        });

        plugins.push(ctrlClickHandler);

        return plugins;
    },
});

const getHighlightedKeywords = (doc, editor, className = 'highlight-keyword') => {
    const { mappedKeywords, activeKeyword, isTurnedOn } = editor.storage.colorHighlighter;

    const decorations = [];

    if (!isTurnedOn || !mappedKeywords?.length) {
        return DecorationSet.empty;
    }

    const mergedTextNodes = [];
    let textNodesIndex = 0;

    doc.descendants((node, position) => {
        if (node.isText) {
            if (mergedTextNodes[textNodesIndex]) {
                mergedTextNodes[textNodesIndex] = {
                    text: mergedTextNodes[textNodesIndex].text + node.text,
                    position: mergedTextNodes[textNodesIndex].position,
                };
            } else {
                mergedTextNodes[textNodesIndex] = {
                    text: node.text,
                    position,
                };
            }
        } else {
            textNodesIndex += 1;
        }
    });

    mergedTextNodes.forEach(({ text, position }) => {
        mappedKeywords.forEach((highlightKeyword) => {
            const escapedKeywordText = highlightKeyword.keyword.toLocaleLowerCase();

            // The keyword is selected by user in an Optimizer Keywords list with Details window opened
            const isActive =
                activeKeyword &&
                (escapedKeywordText === activeKeyword.keyword.toLocaleLowerCase() ||
                    highlightKeyword.regex === activeKeyword.regex);

            const matchedKeywordsArray = collectAllKeywordMatches(text, highlightKeyword);

            matchedKeywordsArray.forEach((match) => {
                const word = match[0];
                const index = match.index || 0;
                const from = position + index;
                const to = from + word.length;
                const decoration = Decoration.inline(from, to, {
                    class: isActive ? `${className} highlight-selected-keyword` : className,
                });

                decorations.push(decoration);
            });
        });
    });

    return DecorationSet.create(doc, decorations);
};

export const HighlightKeywordsExtension = Extension.create({
    name: 'colorHighlighter',

    addOptions() {
        return {
            className: 'highlight-keyword',
        };
    },

    addStorage() {
        return {
            mappedKeywords: [],
            activeKeyword: null,
            isTurnedOn: true,
        };
    },

    addCommands() {
        const { className } = this.options;

        return {
            setKeywordList:
                (keywords = []) =>
                ({ editor }) => {
                    editor.storage.colorHighlighter.mappedKeywords = keywords;

                    return false;
                },
            setActiveKeyword:
                (keyword = null) =>
                ({ editor }) => {
                    if (editor.storage.colorHighlighter.activeKeyword !== keyword) {
                        editor.storage.colorHighlighter.activeKeyword = keyword;
                    }

                    return false;
                },
            applyHighlighting:
                (doc) =>
                ({ editor }) => {
                    getHighlightedKeywords(doc, editor, className);

                    return false;
                },
            setHighlightingOn:
                (isOn = true) =>
                ({ editor }) => {
                    if (editor.storage.colorHighlighter.isTurnedOn !== Boolean(isOn)) {
                        editor.storage.colorHighlighter.isTurnedOn = Boolean(isOn);
                    }

                    return false;
                },
        };
    },

    addProseMirrorPlugins() {
        const editor = this.editor;
        const { className } = this.options;

        return [
            new Plugin({
                key: highlighterPluginKey,
                state: {
                    init() {
                        return;
                    },
                    apply({ doc, docChanged, updated }, oldState) {
                        return docChanged || updated === 0
                            ? getHighlightedKeywords(doc, editor, className)
                            : oldState;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});

export const CustomImageResize = ImageResize.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: 'width: 100%; height: auto; cursor: pointer; max-width: 100%;',
                parseHTML: (element) => {
                    element.style.maxWidth = '100%';
                    element.style.cursor = 'pointer';

                    const width =
                        element.style.width ||
                        getImageWidthForAttribute(element.getAttribute('width')) ||
                        '100%';
                    const margin = element.style.margin || 0;

                    return width
                        ? `width: ${width}; height: auto; cursor: pointer; max-width: 100%; margin: ${margin};`
                        : `${element.style.cssText}`;
                },
            },
            class: {
                default: 'custom-image',
                parseHTML: () => 'custom-image',
                renderHTML: () => {
                    return {
                        class: 'custom-image',
                    };
                },
            },
        };
    },
    addCommands() {
        return {
            alignImage:
                (alignment) =>
                ({ commands, state }) => {
                    const { selection } = state;
                    const { $from, $to } = selection;

                    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
                        if (node.type.name === 'image') {
                            let updatedStyle = node.attrs.style || '';
                            // Remove previous margin if exists
                            updatedStyle = updatedStyle.replace(/margin:\s*[^;]+;?/g, '');
                            // Add new margin according to the alignment
                            updatedStyle += ` margin: ${alignments?.[alignment]?.margin || '0'};`;
                            commands.updateAttributes(node.type.name, {
                                ...node.attrs,
                                style: updatedStyle,
                            });
                        }
                    });
                },
        };
    },
});

/**
 * Custom document extension for the editor.
 *
 * This extends the base Document schema with custom content.
 * The 'block+' value indicates that the document can contain one or more block-level elements.
 * Needed for the editor drag handle to work correctly.
 * @see https://tiptap.dev/api/nodes/document
 */
export const CustomDocument = Document.extend({
    content: 'block+',
});

const DRAG_HANDLE_SELECTOR = '.drag-handle';
const TOP_LEVEL_NODE_SELECTOR = 'p, h1, h2, h3, h4, h5, h6, ul, ol, blockquote';
const DATA_HOVERED_ELEMENT_ATTRIBUTE = 'data-hovered-element';

export const HoverElementExtension = Extension.create({
    name: 'hoverElement',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handleDOMEvents: {
                        mouseover: (view, event) => {
                            const dragHandle = event.target.closest(DRAG_HANDLE_SELECTOR);
                            const target = dragHandle
                                ? null
                                : event.target.closest(TOP_LEVEL_NODE_SELECTOR);

                            if (target) {
                                view.dom.setAttribute(
                                    DATA_HOVERED_ELEMENT_ATTRIBUTE,
                                    target.nodeName.toLowerCase()
                                );
                            }

                            return false;
                        },
                        mouseout: (view, event) => {
                            const relatedDragHandle =
                                event.relatedTarget?.closest(DRAG_HANDLE_SELECTOR);

                            if (!relatedDragHandle) {
                                view.dom.removeAttribute(DATA_HOVERED_ELEMENT_ATTRIBUTE);
                            }

                            return false;
                        },
                    },
                },
            }),
        ];
    },
});

const stripAttributes = (node) => {
    if (node.nodeType === ELEMENT_NODE) {
        if (node.tagName !== 'IMG') {
            node.removeAttribute('style');
        }
        node.childNodes.forEach(stripAttributes);
    }
};

const cleanUpClipboardHtml = async (openStart = 1, openEnd = 1) => {
    const clipboardItems = await navigator.clipboard.read();

    for (const clipboardItem of clipboardItems) {
        if (clipboardItem.types.includes('text/html')) {
            const htmlBlob = await clipboardItem.getType('text/html');
            const htmlString = await htmlBlob.text();
            const plainTextBlob = await clipboardItem.getType('text/plain');

            try {
                // modify clipboard Html
                const blobData = new Blob(
                    [htmlString.replace(` data-pm-slice="${openStart} ${openEnd} []"`, '')],
                    {
                        type: 'text/html',
                    }
                );
                const clipboardItem = new ClipboardItem({
                    'text/html': blobData,
                    'text/plain': plainTextBlob,
                });

                await navigator.clipboard.write([clipboardItem]);
            } catch (err) {
                console.warn('Failed to write to clipboard: ', err);
            }

            break;
        }
    }
};

export const CustomClipboardExtension = Extension.create({
    name: 'customClipboard',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    transformPastedHTML(pastedHtml) {
                        const doc = new DOMParser().parseFromString(pastedHtml, 'text/html');
                        const body = doc.body;
                        stripAttributes(body);
                        const cleanedHtml = body.innerHTML;

                        return cleanedHtml;
                    },
                    clipboardTextSerializer(content, _) {
                        const { openStart, openEnd } = content;
                        cleanUpClipboardHtml(openStart, openEnd);
                    },
                },
            }),
        ];
    },
});

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from 'antd';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectContentOptimizerKeywords } from '../../../../store/contentOptimizerContent.selectors';

import { optimizerTabs } from '../../../../contentOptimizerContentConstants';

import OptimizerKeywordsContent from '../keywords/OptimizerKeywordsContent';
import OptimizerBriefContent from './OptimizerBriefContent';

const classes = {
    optimizerRightBlockWrapper: 'optimizer-right-block-wrapper',
};

const KeywordsBriefWrapper = () => {
    const darkMode = useSelector(selectDarkMode);
    const keywords = useSelector(selectContentOptimizerKeywords);

    const [selectedTab, setSelectedTab] = useState(
        localStorage.getItem('coKeywordsTabKey') || optimizerTabs.keywords.key
    );

    const onChange = (key) => {
        localStorage.setItem('coKeywordsTabKey', key);
        setSelectedTab(key);
    };

    const tabsItems = [
        {
            key: optimizerTabs.keywords.key,
            label: `${optimizerTabs.keywords.label} (${keywords?.length || 0})`,
            children: <OptimizerKeywordsContent />,
        },
        {
            key: optimizerTabs.brief.key,
            label: optimizerTabs.brief.label,
            children: <OptimizerBriefContent />,
        },
    ];

    return (
        <div
            className={`${classes.optimizerRightBlockWrapper}
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Tabs
                destroyInactiveTabPane
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                items={tabsItems}
                onChange={onChange}
            />
        </div>
    );
};

export default KeywordsBriefWrapper;

export const emailPattern =
    // eslint-disable-next-line
    /^([a-z0-9\_\+-]+)(?:\.[a-z0-9\_\+-]+)*@([\da-z]+)(?:\.[a-z0-9]+)*$/;

// One uppercase, one lowercase, one number, one special character, minimum 8 characters
export const passwordPattern = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&-/?/.])[A-Za-z\d#$^+=!*()@%&-/?/.]{8,}$/
);

/** Used for a one row/input link, for example: "Page URL" field in AI Brief */
export const urlPattern = new RegExp(
    // eslint-disable-next-line
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,20}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
);

/**
 * Used for multiple links separated by a line break or whitespace,
 * for example: "Content Examples" field on CB tab
 */
export const urlSpecificPattern = new RegExp(
    // eslint-disable-next-line
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,20}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g
);

/** Usual URL, but allow also commas */
export const competitorUrlPattern = new RegExp(
    // eslint-disable-next-line
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,20}\b(?:[-a-zA-Z0-9()@:%_\+.,~#?&\/=]*)$/
);

export const domainPattern = new RegExp(
    /^(www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(?!\/)$/
);

export const companyNamePattern = new RegExp(
    /^[a-zA-Z0-9][a-zA-Z0-9\s-]*[a-zA-Z0-9]$/
);

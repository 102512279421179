import React, { useEffect, useRef } from 'react';

import { Button, Input } from 'antd';

const classes = {
    addLinkWrapper: 'add-link-wrapper d-flex',
    addLinkButton: 'add-link-button',
};

const AddLinkContent = ({ handleCollapse, editor }) => {
    const linkInputRef = useRef(null);

    useEffect(() => {
        if (linkInputRef.current) {
            linkInputRef.current.focus();
        }

        return () => {
            editor.commands.focus();
        };
    }, []);

    const handleAddLink = () => {
        const linkValue = linkInputRef.current?.input?.value;

        if (linkValue) {
            handleCollapse();

            const { view, state } = editor;
            const { from, to } = view.state.selection;
            const text = state.doc.textBetween(from, to, '');

            if (text) {
                editor.chain().focus().extendMarkRange('link').setLink({ href: linkValue }).run();
            } else {
                editor.commands.insertContent(`<a href="${linkValue}">${linkValue}</a>`);
            }
        }
    };

    return (
        <div className={`${classes.addLinkWrapper}`}>
            <Input
                ref={linkInputRef}
                placeholder={'Type or paste a URL'}
                onPressEnter={() => {
                    handleAddLink();
                }}
            />
            <Button type='primary' className={classes.addLinkButton} onClick={handleAddLink}>
                Save
            </Button>
        </div>
    );
};

export default AddLinkContent;

import { authPathes, paymentsPathes } from '../../../../constants/queryPathes';
import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';

export const SubscriptionPlansService = {
    getProducts: () =>
        axiosAPI.get(paymentsPathes.getProducts, {
            ...getAxiosHeaders(),
        }),

    createSubscription: (selectedPlanId, isTrial, userBillingEmail, user) =>
        axiosAPI.post(
            paymentsPathes.createSubscription,
            {
                price_id: selectedPlanId,
                trial: isTrial,
                email: userBillingEmail,
                name: `${user.first_name} ${user.last_name}`,
                organization_name: user.organization.name,
            },
            { ...getAxiosHeaders() }
        ),

    addSubscriptionToExistingUser: (user, subscriptionId, customerId) =>
        axiosAPI.post(
            authPathes.addSubscriptionToExistingUser,
            {
                company_name: user.organization.name,
                subscription_id: subscriptionId,
                customer_id: customerId,
                user_id: user.id,
            },
            { ...getAxiosHeaders() }
        ),
};

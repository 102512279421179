export const authPathes = {
    login: '/auth/login',
    logout: '/auth/logout',
    checkAuthToken: '/auth/check-auth-token',
    users: '/auth/users',
    checkEmailExisting: '/auth/check-email-existing',
    checkCompanyNameExisting: '/auth/check_company_name_existing',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset',
    confirm: '/auth/confirm',
    getConfirmInstructions: '/auth/get-confirm-instructions',
    addSubscriptionToExistingUser: '/auth/users/add-subscription',
    resendConfirm: '/auth/users/resend-confirm',
};

export const userDataPathes = {
    users: 'auth/users',
    changePassword: '/auth/change-password',
    changeUserRole: '/auth/users/',
    usage: '/auth/usage',
    changeAdminEmail: '/admin-email',
};

export const keywordPathes = {
    createKeywordResearch: '/keywords/researches',
    getListOfKeywordResearches: '/keywords/researches',
    getKeywordResearchInfo: '/keywords/researches/',
    deleteKeywordResearch: '/keywords/researches/',
};

export const organizationPathes = {
    updateOrganization: '/auth/organizations',
    getOrganization: '/auth/organizations/',
};

export const invitesPathes = {
    getListOfInvites: '/auth/invites',
    createInvite: '/auth/invites',
    getInvite: '/auth/invites/',
    deleteInvite: '/auth/invites/',
};

export const contentBriefPathes = {
    getListOfContentBriefs: '/content-briefs/briefs',
    createContentBrief: '/content-briefs/briefs',
    getContentBrief: '/content-briefs/briefs/',
    getContentBriefStatus: '/content-briefs/briefs/status',
    updateContentBrief: '/content-briefs/briefs/',
    deleteContentBrief: '/content-briefs/briefs/',
    bulkUpdateContentBriefs: '/content-briefs/briefs',
    bulkDeleteContentBriefs: '/content-briefs/briefs',
    generateOutline: '/content-briefs/briefs',
};

export const contentOptimizerPathes = {
    getContentOptimizers: '/content-optimizers/optimizers',
    editContentOptimizer: '/content-optimizers/optimizers',
    deleteContentOptimizer: '/content-optimizers/optimizers',
    importFromUrl: '/content-optimizers/import-from-url',
    scanUrl: '/content-optimizers/scan-url',
    refreshUrl: '/content-optimizers/refresh-scan-url',
    deleteUrl: '/content-optimizers/delete-scan-url',
    includeInOptimalRange: '/content-optimizers/include-in-optimal-range',
    resetCompetitorsToDefault: '/content-optimizers/reset-competitors-to-default',
    imageStorage: '/content-optimizers/image-storage',
};

export const projectPathes = {
    getListOfProjects: '/content-briefs/projects',
    createProject: '/content-briefs/projects',
};

export const paymentsPathes = {
    getSubscription: '/payments/subscriptions',
    createSubscription: '/payments/subscriptions',
    editSubscription: '/payments/subscriptions/update',
    cancelSubscription: '/payments/subscriptions/cancel',
    resumeSubscription: '/payments/subscriptions/resume',
    createCustomer: '/payments/customers',
    getProducts: '/payments/products',
    getCreditPrice: '/payments/credit-price',
    getInvoices: '/payments/invoices',
    getUpcomingInvoice: '/payments/invoices/upcoming',
    getReceipt: '/payments/invoices',
    getPaymentMethods: '/payments/payment-methods',
    editPaymentMethod: '/payments/payment-methods',
    getQuotes: '/payments/quotas',
    decrementOpenAiBriefsCredits: '/payments/quotas/decrement_openai_briefs',
    addCredits: '/payments/credits',
};

export const globalAdminPathes = {
    getOrganizations: '/admin/organizations',
    usage: '/admin/usage',
    getUsers: '/admin/users',
    changeUserRole: '/auth/users/',
};

export const contentMonitorPathes = {
    baseContentMonitorUrl: '/content-monitors/monitors',
    cmProject: '/content-monitors/projects',
    contentMonitorStatus: '/content-monitors/monitors/status',
};

export const cancellationPaths = {
    getCancellationReasons: '/cancellations/cancellation-reasons',
    addCancellation: '/cancellations',
    getCancellation: '/cancellations',
};

export const organizationsSortFields = {
    created_at: 'created_at',
    name: 'name',
};

export const usersSortFields = {
    created_at: 'created_at',
    email: 'email',
    full_name: 'first_name',
};

export const usageStatsTableFields = {
    user: {
        key: 'user',
        title: 'user',
        width: '24%',
    },
    organization: {
        key: 'organization',
        title: 'organization',
        width: '24%',
    },
    contentBriefs: {
        key: 'contentBriefs',
        title: 'optimizers',
        width: '13%',
    },
    contentMonitors: {
        key: 'contentMonitors',
        title: 'monitors',
        width: '13%',
    },
    keywordResearches: {
        key: 'keywordResearches',
        title: 'keywords',
        width: '13%',
    },
    aiCredits: {
        key: 'aiCredits',
        title: 'ai credits',
        width: '13%',
    },
};
